import CategoryBlock from '@/components/category/category-block'
import CategorySlider from '@/components/category/category-slider'

const CategoryGrid = (props) => {
  return (
    <>
      <div className="hidden grid-cols-2 gap-9-5 md:grid-cols-3 lg:grid">
        {
          props.contentBlockCollection.items.length > 0 &&
          props.contentBlockCollection.items.map((category, index) => (
            <CategoryBlock {...category} key={index} />
          ))
        }
      </div>
      <div className="lg:hidden">
        <CategorySlider {...props} />
      </div>
      <style jsx>{
        `
          .gap-9-5 {
            grid-gap: 9.5px;
            gap: 9.5px;
          }
        `
      }
      </style>
    </>
  )
}

export default CategoryGrid;