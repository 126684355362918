import CategoryBlock from '@/components/category/category-block'
import CategorySlider from '@/components/category/category-slider'

const Category3Up = (props) => {

    return (
        <>
            <div className="category-3up hidden justify-center grid-cols-3 gap-9-5 md:grid">
                {
                    props.contentBlockCollection.items.length > 0 &&
                    props.contentBlockCollection.items.map((category, index) => {
                        if (index <= 2) {
                            return (
                              <CategoryBlock {...category} key={index} />
                            )
                        }
                    })
                }
            </div>
            <div className="category-3up md:hidden">
                <CategorySlider {...props} />
            </div>

            <style jsx>{
                `
                    .gap-9-5 {
                        grid-gap: 9.5px;
                        gap: 9.5px;
                    }
                `
                }
            </style>
        </>
    )
}

export default Category3Up;