import { useEffect } from 'react'
import CategoryBlock from '@/components/category/category-block'
import { removePx, initSlider } from '@/lib/helpers'
import styleConfig from '@/styles/style-config'
import { v4 as uuidv4 } from 'uuid'
import Icon from '@/components/icon/icon'

const CategorySlider = (props) => {
  const id = uuidv4()

  const sliderSelector = `splide-${props.sys.id}`

  useEffect(() => {
    const slider = initSlider(`.${sliderSelector}`, {
      gap: '0.75rem',
      pagination: false,
      perPage: 3,
      perMove: 1,
      // arrows: false,
      breakpoints: {
        [removePx(styleConfig.theme.screens.md)]: {
          perPage: 2,
          perMove: 1,
          fixedWidth: '233px'
        },
        [removePx(styleConfig.theme.screens.sm) - 1]: {
          perPage: 1,
          perMove: 1,
          fixedWidth: '243px'
        },
      },
    });
  })

  return (
    <>
      <div className={`splide ${sliderSelector} mt-8 md:pt-7`}>
        <div className="splide__arrows absolute top-0 right-0 hidden lg:block mr-[60px]">
          <button className="splide__arrow splide__arrow--prev">
            <Icon className={`cursor-pointer`} viewBox={`0 0 36 28`} size={28} icon="slide-arrow-left"/>
          </button>
          <button className="splide__arrow splide__arrow--next">
            <Icon className={`cursor-pointer`} viewBox={`0 0 36 28`} size={28} icon="slide-arrow-right" />
          </button>
        </div>
        <div className="splide__track">
          <ul className="splide__list flex">
            {props.contentBlockCollection.items.length > 0 &&
              props.contentBlockCollection.items.map((category, index) => (
                <li className="splide__slide" key={index}>
                  <CategoryBlock {...category} />
                </li>
              ))}
          </ul>
        </div>
      </div>
      <style jsx>{
        `
        .splide__track {
          overflow: visible !important;
        }
        .splide__arrows {
          margin-right: 40px;
        }
        `
      }
      </style>
    </>
  )
}

export default CategorySlider;