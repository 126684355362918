import CategoryBlock from '@/components/category/category-block'
import CategorySlider from '@/components/category/category-slider'

const Category4Up = (props) => {
    return (
        <>
            <div className="hidden grid-cols-4 gap-9-5 md:grid">
                {
                    props.contentBlockCollection.items.length > 0 &&
                    props.contentBlockCollection.items.map((category, index) =>  {
                        if (index <= 3) {
                          return (
                            <CategoryBlock {...category} key={index} type="4up" />
                          )
                        }
                    })
                }
            </div>
            <div className="md:hidden">
                <CategorySlider {...props} />
            </div>
            <style jsx>{
                    `
                        .gap-9-5 {
                            grid-gap: 9.5px;
                            gap: 9.5px;
                        }
                    `
                }
            </style>
        </>
    )
}

export default Category4Up;