import { addForwardSlash } from '@/lib/helpers'
import Image from 'next/image'
import styleConfig from '@/styles/style-config'
import Link from 'next/link'
import Icon from '../icon/icon'
import { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const CategoryBlock = (props) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window != undefined) {
      setIsMobile(document.body.clientWidth >= 768 ? false : true)
      window.addEventListener('resize', function (event) {
        setIsMobile(document.body.clientWidth >= 768 ? false : true)
      })
    }
  }, [])

  return (
    <>
      <div className="category-section flex flex-col h-full">
        <div className="relative transform transition hover:scale-scale-y-101 category-block">
          {isMobile ? (
            <Link href={addForwardSlash(props.ctaUrl)}>
              <div className="category group relative cursor-pointer">
                <CategoryBlockImage
                  isMobile={isMobile}
                  imageProps={{
                    priority: true,
                    quality: 85,
                    objectFit: 'cover',
                    srcMap: {
                      mobile:
                        props.mobileBackgroundImage?.url ||
                        props.tabletBackgroundImage?.url ||
                        props.backgroundImage?.url,
                      tablet: props.tabletBackgroundImage?.url || props.backgroundImage?.url,
                      desktop: props.backgroundImage?.url,
                    },
                  }}
                />
              </div>
            </Link>
          ) : (
            <div className="category group relative cursor-pointer">
              <CategoryBlockImage
                isMobile={isMobile}
                imageProps={{
                  priority: true,
                  quality: 85,
                  objectFit: 'cover',
                  srcMap: {
                    mobile:
                      props.mobileBackgroundImage?.url ||
                      props.tabletBackgroundImage?.url ||
                      props.backgroundImage?.url,
                    tablet: props.tabletBackgroundImage?.url || props.backgroundImage?.url,
                    desktop: props.backgroundImage?.url,
                  },
                }}
              />
              {props.ctaUrl && props.ctaText && (
                <div className="flex justify-center items-center absolute inset-0 ctaText-section bg-gray-800 bg-opacity-50 leading-18">
                  <Link
                    href={addForwardSlash(props.ctaUrl)}
                    className="px-5 py-4 rounded-full absolute ctaText whitespace-no-wrap border font-normal border-white text-white text-smd hover:border-gray-500 active:border-gray-600"
                  >
                    {props.ctaText}
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="w-full text-left flex flex-col justify-between flex-1">
          <div className="break-words text-left slider-ui">
            <h2 className="title uppercase text-black font-primary mb-2.5 mt-5 w-full" dangerouslySetInnerHTML={{ __html: props.title }}>
              {/* {props.title} */}
            </h2>
            {props.bodyText !== null && (
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props.bodyText}</ReactMarkdown>
            )}
          </div>
          <div className="flex flex-col mb-1">
            {props.ctaLeftUrl && props.ctaLeftText && (
              <div className="flex items-center mt-30 w-full">
                <Link
                  href={addForwardSlash(props.ctaLeftUrl)}
                  className="leading-18 font-primary text-smd font-normal border-b lg:whitespace-no-wrap border-orange-500 hover:text-gray-600 active:text-gray-400"
                >
                  {props.ctaLeftText}
                </Link>
                <Icon
                  className={`cursor-pointer ml-3 mt-1`}
                  viewBox={`0 0 12 12`}
                  size={12}
                  fill={'#E36F22'}
                  icon="arrow"
                />
              </div>
            )}
            {props.ctaRightUrl && props.ctaRightText && (
              <div className="flex items-center mt-30 w-full">
                <Link
                  href={addForwardSlash(props.ctaRightUrl)}
                  className="leading-18 font-primary text-smd font-normal border-b lg:whitespace-no-wrap border-orange-500 hover:text-gray-600 active:text-gray-400"
                >
                  {props.ctaRightText}
                </Link>
                <Icon
                  className={`cursor-pointer ml-3 mt-1`}
                  viewBox={`0 0 12 12`}
                  size={12}
                  fill={'#E36F22'}
                  icon="arrow"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .slider-ui {
            ul{
              list-style-position: outside !important; 
              padding-left: 10px
            }
          }
          .category {
            -webkit-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
            opacity: 1;
            padding-top: ${100 / (243 / 292)}%;
          }

          .category:hover .ctaText-section {
            opacity: 1;
            z-index: 11;
            display: flex;
          }

          .category:hover .ctaText {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
            height: 55px;
          }

          .ctaText {
            bottom: 0;
            opacity: 0;
            transition: 0.3s ease-in-out;
            left: 50%;
            top: 60%;
            transform: translate(-50%, -50%);
            height: 55px;
          }

          .ctaText-section {
            -webkit-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
            opacity: 0;
            z-index: -1;
          }

          .title {
            font-size: 20px;
            line-height: 18px;
            letter-spacing: -0.5px;
          }
          .mt-26 {
            margin-top: 26.5px;
          }
          .mr-36 {
            margin-right: 36.25px;
          }

          @media screen and (min-width: ${styleConfig.theme.screens.md}) {
            .category {
              padding-top: ${100 / (props.type === '4up' ? 172 / 207 : 233 / 280)}%;
            }

            .title {
              font-size: 22px;
              line-height: 20px;
            }
          }

          @media screen and (min-width: ${styleConfig.theme.screens.lg}) {
            .category {
              padding-top: ${100 / (props.type === '4up' ? 242 / 290 : 325 / 390)}%;
            }

            .title {
              line-height: 26px;
              font-size: 28px;
            }

            .mt-30 {
              margin-top: 30.5px;
            }
          }

          @media screen and (min-width: ${styleConfig.theme.screens.xl}) {
            .category {
              padding-top: ${100 / (props.type === '4up' ? 372 / 445 : 499 / 600)}%;
            }

            .title {
              line-height: 30px;
              font-size: 32px;
            }
          }
        `}
      </style>
    </>
  )
}
export default CategoryBlock

const deviceSizeMap = [
  'xxs',
  'md',
  'lg',
  'xl',
  'xxl',
  'g_sm',
  'g_md',
  'custom_sm',
  'custom_md',
].reduce(
  (map, sizeKey) => ({
    ...map,
    [sizeKey]: Number(styleConfig.theme.screens[sizeKey].replace('px', '')),
  }),
  {}
)

const defaultSizes = [
  `(max-width: 768px) 243px, (max-width: 1024px) 256px, (max-width: 1535px) 384px, 600px`,
].join(',')

function handleContenfulImage({ src = '', width, quality }, isMobile) {
  // Sometimes, height is passed directly in the src. We need to parse it out and build a new URL.
  const x = new URL(src)
  const customHeight = x.searchParams.get('h')
  const imageUrl = src.split('?')[0]

  const queryParams = ['fm=webp']

  if (width) {
    // const mobileSizeArr = [16, 32, 48, 64, 96, 128, 256]
    // const desktopSizeArr = [96, 128, 256, 384, 640, 750]

    // const arr = isMobile === true ? mobileSizeArr : desktopSizeArr

    const arr = [
      16, 32, 48, 64, 96, 128, 256, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1535, 1920, 2048,
      3840,
    ]

    const customWidth = arr.reduce((a, b) => {
      return Math.abs(b - width) < Math.abs(a - width) ? b : a
    })

    if (customWidth) {
      queryParams.push(`w=${customWidth}`)
    }
  }

  if (customHeight) {
    queryParams.push(`h=${customHeight}`)
  }
  if (quality) {
    queryParams.push(`q=${quality}`)
  }

  return queryParams.length ? `${imageUrl}?${queryParams.join('&')}` : src
}

const contentfulLoader = (loadedProps) => {
  const { src } = loadedProps
  if (src?.includes('ctfassets')) return handleContenfulImage(loadedProps, false)
  return src
}
const contentfulMobileImageLoader = (loadedProps) => {
  const { src } = loadedProps
  if (src?.includes('ctfassets')) return handleContenfulImage(loadedProps, true)
  return src
}

const CategoryBlockImage = ({ src, alt = '', imageProps = {}, isMobile, loading = undefined }) => {
  const { priority = false, quality = 85, objectFit = 'contain', srcMap } = imageProps
  const [srcUrl, setSrcUrl] = useState({
    src: '',
    width: 0,
  })
  const [customClass, setCustomClass] = useState('')

  function handleImageChangeOnResize() {
    if (srcMap) {
      if (window.innerWidth > deviceSizeMap.xxs && window.innerWidth < deviceSizeMap.custom_sm) {
        setSrcUrl({
          src: srcMap.mobile,
          width: deviceSizeMap.g_sm,
        })
      } else if (
        window.innerWidth > deviceSizeMap.md &&
        window.innerWidth < deviceSizeMap.custom_md
      ) {
        setSrcUrl({
          src: srcMap.tablet,
          width: deviceSizeMap.g_md,
        })
      } else {
        setSrcUrl({
          src: srcMap.desktop,
          width: deviceSizeMap.xl,
        })
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleImageChangeOnResize)
    return () => {
      window.removeEventListener('resize', handleImageChangeOnResize)
    }
  }, [])

  useEffect(() => {
    handleImageChangeOnResize()

    // Check if background style has been set.
    if (objectFit === 'cover') {
      setCustomClass('object-cover')
    }

    if (objectFit === 'contain') {
      setCustomClass('object-contain')
    }
  }, [])

  return (
    <Image
      loader={isMobile ? contentfulMobileImageLoader : contentfulLoader}
      src={srcMap ? `${srcUrl.src}` : `${src}`}
      className={customClass}
      sizes={defaultSizes}
      alt={alt}
      fill
      quality={quality}
      priority={priority}
      loading={loading}
    />
  )
}
