import Category3Up from '@/components/category/category-3up'
import Category4Up from '@/components/category/category-4up'
import { confirmPassword } from '@/lib/validation-schema'
import styleConfig from '@/styles/style-config'
import CategoryGrid from './category-grid'
import CategorySlider from './category-slider'

const CategorySelector = (props) => {
  return (
    <>
      <div
        className={`category-selector container-xl product-padding ${
          props.type === 'Grid' ? 'category-grid' : ''
        } ${props.type === 'Slider' ? 'mt-11 mb-10' : ''} ${
          props.type === '4 up' ? 'category-mb' : ''
        }`}
      >
        {props.displayTitle && (
          <div className="text-left mb-5 md:mb-15">
            <h3 className="uppercase font-primary font-normal category-title">{props.title}</h3>
          </div>
        )}
        {props.type === '3 up' && <Category3Up {...props} />}

        {props.type === '4 up' && <Category4Up {...props} />}

        {props.type === 'Slider' && <CategorySlider {...props} />}

        {props.type === 'Grid' && <CategoryGrid {...props} />}
      </div>
      <style jsx>
        {`
          .category-selector {
            padding-top: 25px;
            padding-bottom: 25px;
          }

          .product-padding {
            padding-left: 25px;
            padding-right: 25px;
          }

          .category-grid {
            margin-top: 76px;
          }
          .category-mb {
            margin-bottom: 40px;
          }

          .category-title {
            font-size: 20px;
            line-height: 18px;
            letter-spacing: -0.5px;
          }

          @media (min-width: ${styleConfig.theme.screens.md}) {
            .category-grid {
              margin-top: 56px;
            }
            .category-mb {
              margin-bottom: 50px;
            }
            .category-title {
              font-size: 22px;
              line-height: 20px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.lg}) {
            .product-padding {
              padding-left: 20px;
              padding-right: 20px;
            }
            .category-grid {
              margin-top: 86px;
            }
            .category-mb {
              margin-bottom: 100px;
            }
            .category-selector {
              padding-top: 20px;
              padding-bottom: 20px;
            }
            .category-title {
              font-size: 28px;
              line-height: 26px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.xl}) {
            .product-padding {
              padding-left: 60px;
              padding-right: 60px;
            }
            .category-grid {
              margin-top: 96px;
            }
            .category-mb {
              margin-bottom: 120px;
            }

            .category-selector {
              padding-top: 60px;
              padding-bottom: 60px;
            }
            .category-title {
              font-size: 32px;
              line-height: 30px;
            }
          }
        `}
      </style>
    </>
  )
}

export default CategorySelector
