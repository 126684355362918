import * as Yup from 'yup'
import * as EmailValidator from 'email-validator'
import { trans } from '@/lib/locale-helper'

export const firstNameWishlist = Yup.string()
  //.required('First name is a required field')
  .matches(
    /^[a-zA-Z]{1,100}$/,
    trans('alphabets-only-error', 'Must contain alphabets only. No numeric values. No Spaces allowed. Maximum 100 characters allowed.')
  )

export const lastNameWishlist = Yup.string()
  //.required('Last name is a required field')
  .matches(
    /^[a-zA-Z]{1,100}$/,
    trans('alphabets-only-error', 'Must contain alphabets only. No numeric values. No Spaces allowed. Maximum 100 characters allowed.')
  )

export const firstName = Yup.string()
  .required(`${trans('first_name', 'First Name')} ${trans('is-a-required-field', 'is a required field')}`).max(100, trans('signup-firstname-error', 'First Name cannot contain more than 100 characters.'))
  .matches(
    // /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]*$/
    /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\-.]+(?:[-\s][a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\-.]+)?$/,
    `${trans('only-alphabet-no-spaces-error', "Only alphabet letters here; you've got 26 to choose from. Also, no spaces, and a max of 100 characters")}`
  )

export const lastName = Yup.string()
  .required(`${trans('last_name', 'Last Name')} ${trans('is-a-required-field', 'is a required field')}`).max(100, trans('signup-firstname-error', 'First Name cannot contain more than 100 characters.'))
  .matches(
    // /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]*$/
    /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\-.]+(?:[-\s][a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\-.]+)?$/,
    `${trans('only-alphabet-no-spaces-error', "Only alphabet letters here; you've got 26 to choose from. Also, no spaces, and a max of 100 characters")}`
  )

export const email = Yup.string()
  .email("Hmm, we don't recognize that email format")
  .required(`${trans('email-address', 'Email Address')} ${trans('is-a-required-field', 'is a required field')}`)
  .matches(/^.{1,128}$/, { message: `${trans('max-128-chars', 'Maximum 128 characters allowed')}` })
  //.matches(/[a-zA-Z0-9.!#$%&*+=?^_{|}~-]$/, { message: 'Allow special character only [.!#$%&*+=?^_{|}~-]' })
  .matches(/^[a-zA-Z0-9.!#$%&*+=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, {
    message: `${trans('email-special-chars-error','Invalid email address, Allowed special characters are [.!#$%&*+=?^_{|}~-]')}`,
  })
  .test(
    'valid-email',
    `${trans('unrecognized-email-error', "Hmm, we don't recognize that email format" )}`,
    (value) => {
      if (value) {
        return EmailValidator.validate(value) // true
      }
      return true
    }
  )

export const confirmEmail = Yup.string()
  .required('Confirm email address is a required field.')
  .oneOf([Yup.ref('email'), null], "Oops, emails don't match. Try again")

export const password = Yup.string()
  .required(`${trans('password', 'Password')} ${trans('is-a-required-field', 'is a required field')}`)
  .matches(/^(?:(.)(?!\1\1))+$/, {
    message: `${trans('password-repeated-char-error', 'Can only repeat the same character 2 times in a row')}`,
  })
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$()\[\].,])[A-Za-z\d!@#$()\[\].,]{8,20}$/,
    `${trans('password-rules', 'Please ensure your password is 8 to 20 characters long with one uppercase letter, one lowercase letter, one number and one special character. Allowed special characters are ! @ # $ ( ) [ ] . ,')}`
  )
  .test('test-unique', `${trans('password-unique-chars-rule', 'Must include 5 unique characters')}`, (value) => {
    if (value) {
      const charArr = value.split('')
      //console.log('charArr', charArr)
      const unique = [...new Set(charArr)]
      //console.log('unique', unique)
      if (unique.length > 4) {
        return true
      } else {
        return false
      }
    }
    return true
  })

export const confirmPassword = Yup.string()
  .required(`${trans('password', 'Confirm Password')} ${trans('is-a-required-field', 'is a required field')}`)
  .oneOf([Yup.ref('password'), null], `${trans('password-match-error', 'Oops passwords don’t match. Try again')}`)

export const birthDate = Yup.date().nullable()


export const reviewTitle = Yup.string().trim().required(trans( "review-title-important","Add a title here, it's important"))

export const reviewName = Yup.string().trim()
  .required(trans('name-is-required' , 'Please provide your name'))
  .matches(
    /^[a-zA-Z ]{1,200}$/,
    "Only alphabet letters here; you've got 26 to choose from. Also, a max of 200 characters"
  )

export const reviewEmail = Yup.string().trim()
  .email("Hmm, we don't recognize that email format")
  .required(trans('enter-email-required' ,'Enter your email address, please'))
  .matches(/^.{1,128}$/, { message: 'Maximum 128 characters are allowed' })
  .test('valid-email', "Hmm, we don't recognize that email format", value => {
    if (value) {
      return EmailValidator.validate(value) // true
    }
    return true
  })
